/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

function AircraftManagement() {
  return (
    <>
      <div className="section section-buttons">
        <Container>
          <div className="title">
            <h2>Elevating Your Aircraft Ownership Experience
              <br/>
              <small>At Ascend, we understand that aircraft ownership represents a significant investment of both time and resources. That\'s why our Aircraft Management Services are designed to alleviate the complexities and enhance the joys of owning and operating your aircraft.</small>
            </h2>

          </div>
          <div id="buttons">
            <div className="title">
              <h3>
                Why Choose Ascend for Aircraft Management? <br />
                <small>
                <ul>
                    <li><strong>Safety First:</strong> Safety is our top priority. We adhere to the highest industry standards and regulatory compliance to ensure your aircraft is always in peak condition.</li>
                    <li><strong>Experienced Team:</strong> Our dedicated team of aviation experts, including pilots, and management professionals, bring years of experience to every aspect of your aircraft\'s management.</li>
                    <li><strong>Tailored Solutions:</strong> We recognize that every aircraft owner is unique. Our services are fully customizable to meet your specific needs, whether you own a single-engine plane or a fleet of jets.</li>
                    <li><strong>Cost Efficiency:</strong> We\'re committed to optimizing your aircraft\'s financial performance, helping you reduce operational costs while maintaining quality.</li>
                  </ul>
                  </small>
              </h3>
            </div>
            <div className="title">
              <h3>
                Our Comprehensive Aircraft Management Services <br />
                <small>
                  <ul>
                    <li><strong>Aircraft Maintenance:</strong> We schedule and oversee all maintenance, from routine inspections to major repairs, ensuring your aircraft\'s reliability.</li>
                    <li><strong>Operational Support:</strong> Our team manages the logistics, flight planning, and crew scheduling, so you can enjoy stress-free flights without the administrative burden.</li>
                    <li><strong>Financial Management:</strong> Ascend helps you make informed decisions to maximize your aircraft\'s financial efficiency.</li>
                    <li><strong>Aircraft Acquisition and Sales:</strong> Whether you\'re buying a new aircraft or selling your current one, we offer expert guidance throughout the process.</li>
                    <li><strong>Aviation Consulting Services:</strong> We offer a full range of specialized advisory and support for our clients ranging from regulations, to operations and management.</li>
                    <li><strong>Contract Pilot Services:</strong> We provide highly trained and experienced pilots to organizations or individuals in need of pilot services.</li>
                  </ul>                  
                  </small>
              </h3>
            </div>
            <div className="title">
              <h3>
              Experience the Ascend Advantage <br />
                <small>At Ascend Aircraft Management, we treat your aircraft as if it were our own. We\'re committed to delivering a superior ownership experience, allowing you to focus on the exhilaration of flying while we handle the rest.</small>
              </h3>
            </div>
            </div>
        </Container>
      </div>
    </>
  );
}

export default AircraftManagement;
