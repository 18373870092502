/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

function AboutAscend() {
  return (
    <>
      <div className="section section-buttons">
        <Container>
          <div className="title">
            <h2>About Ascend Aircraft Management
              <br/>
              <small>Welcome to Ascend Aircraft Management, where our passion for aviation meets our commitment to excellence. We take pride in being a trusted partner for aircraft owners, providing top-tier management services that make the skies more accessible, safer, and more enjoyable.</small>
            </h2>

          </div>
          <div id="buttons">
            <div className="title">
              <h3>
                Our Story <br />
                <small>Founded by aviation enthusiasts with a vision, Ascend Aircraft Management was born out of a desire to revolutionize the aircraft ownership experience. With years of industry knowledge and a deep passion for business aviation, we set out to create a company that would redefine the standards of aircraft management.</small>
              </h3>
            </div>
            <div className="title">
              <h3>
                Our Mission <br />
                <small>At Ascend, our mission is clear: to elevate the aviation experience for every aircraft owner. We achieve this by delivering comprehensive, personalized management services that empower our clients to maximize the potential of their aircraft, whether for business, pleasure, or investment.</small>
              </h3>
            </div>
            <div className="title">
              <h3>
                Why Choose Ascend? <br />
                <small>
                  <ul>
                    <li><strong>Passionate Team:</strong> Our team is comprised of aviation experts who share a genuine love for flying. We\'re dedicated to nurturing a culture of enthusiasm and expertise that benefits every client we serve.</li>
                    <li><strong>Safety Above All:</strong> Safety is non-negotiable at Ascend. We maintain the highest safety standards, ensuring your aircraft is always in top condition and your flights are secure.</li>
                    <li><strong>Tailored Solutions:</strong> We understand that each aircraft owner has unique requirements. Our services are fully customizable, allowing us to meet your specific needs, whatever they may be.</li>
                    <li><strong>Financial Excellence:</strong> We are committed to helping you optimize the financial performance of your aircraft, providing strategies to enhance your investment.</li>
                    <li><strong>Global Network:</strong> Our vast industry connections and global reach ensures that we can connect you with the best opportunities in aircraft acquisition, sales, and charter services.</li>
                  </ul>                  
                  </small>
              </h3>
            </div>
            <div className="title">
              <h3>
                Our Commitment <br />
                <small>At Ascend Aircraft Management, we treat your aircraft as if it were our own. We are dedicated to providing you with a seamless, worry-free ownership experience. Our commitment to excellence extends to every service we offer, from maintenance and operations to financial management and beyond.</small>
              </h3>
            </div>
            <div className="title">
              <h3>
                Join the Ascend Community <br />
                <small>Whether you're a seasoned aviator, a business executive, or an aspiring pilot, Ascend Aircraft Management welcomes you to join our community of aviation enthusiasts. Experience the difference of working with a team that is passionate about helping you soar to new heights.</small>
              </h3>
            </div>
            </div>
        </Container>
      </div>
    </>
  );
}

export default AboutAscend;
