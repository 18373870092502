/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

function Consulting() {
  return (
    <>
      <div className="section section-buttons">
        <Container>
          <div className="title">
            <h2>Ascend Consulting Services
              <br/>
              <small>At Ascend Consulting Services, we offer expert guidance and solutions to optimize your aircraft management operations. With a team of seasoned aviation professionals, we are committed to enhancing the efficiency, safety, and profitability of your aviation ventures.</small>
            </h2>

          </div>
          <div id="buttons">
            <div className="title">
              <h3>
                Why Choose Ascend for Consulting Services? <br />
                <small>
                <ul>
                    <li><strong>Experience:</strong> Our team brings decades of experience in aviation, spanning various aircraft types and industries.</li>
                    <li><strong>Tailored Solutions:</strong> We understand that every client is unique. Our solutions are customized to meet your specific needs and objectives.</li>
                    <li><strong>Industry Insights:</strong> We stay updated with industry trends, regulations, and best practices, ensuring you receive the most relevant advice.</li>
                    <li><strong>Client-centric Approach:</strong> Your success is our priority. We work closely with you to achieve your aviation goals.</li>
                    <li><strong>Confidentiality:</strong> Your data and information are treated with the utmost confidentiality and security.</li>
                  </ul>
                  </small>
              </h3>
            </div>
            <div className="title">
              <h3>
                Our Consulting Services <br />
                <small>
                  <ul>
                    <li><strong>Aircraft Selection and Acquisition:</strong> Our consultants will assist you in choosing the right aircraft for your needs, ensuring cost-effectiveness and performance.</li>
                    <li><strong>Operational Efficiency:</strong> We analyze your current operations and recommend strategies to streamline processes, reduce downtime, and enhance productivity.</li>
                    <li><strong>Regulatory Compliance:</strong> Stay up-to-date with ever-changing aviation regulations. We provide guidance on compliance with local and international aviation authorities.</li>
                    <li><strong>Safety Audits:</strong> Safety is paramount in aviation. Our experts conduct comprehensive safety audits to identify potential risks and recommend mitigation measures.</li>
                    <li><strong>Financial Management:</strong> Optimize your aviation budget. We offer financial analysis and planning services to maximize profitability.</li>
                    <li><strong>Crew Training and Development:</strong> Ensure your crew is well-trained and up to date with the latest industry standards. We provide customized training programs.</li>
                    <li><strong>Maintenance and Repairs:</strong> Minimize aircraft downtime with our maintenance scheduling and repair management solutions.</li>
                  </ul>                  
                  </small>
              </h3>
            </div>
            </div>
        </Container>
      </div>
    </>
  );
}

export default Consulting;
