/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

function ContractPiloting() {
  return (
    <>
      <div className="section section-buttons">
        <Container>
          <div className="title">
            <h2>Contract Pilot Services
              <br/>
              <small>We understand that every flight is unique, and your aviation needs may vary. That's why we offer specialized Contract Pilot Services to ensure your aircraft is in the hands of experienced professionals. Our team of skilled and certified pilots is ready to provide top-notch service tailored to your requirements.</small>
            </h2>

          </div>
          <div id="buttons">
            <div className="title">
              <h3>
                Why Choose Us? <br />
                <small>
                <ul>
                    <li><strong>Tailored Solutions:</strong> We work closely with you to understand your unique flight requirements and tailor our services to meet your exact needs.</li>
                    <li><strong>Cost Effective:</strong> Hiring contract pilots can be a cost-effective solution, allowing you to pay for the expertise you need, only when you need it.</li>
                    <li><strong>Peace of Mind:</strong> With our contract pilot services, you can have peace of mind knowing that your aircraft is in the hands of professionals who prioritize safety and efficiency.</li>
                    <li><strong>Time Savings:</strong> Let us handle the logistics of pilot recruitment and training, saving you time and effort.</li>
                  </ul>
                  </small>
              </h3>
            </div>
            <div className="title">
              <h3>
                Our Contract Pilot Services <br />
                <small>
                  <ul>
                    <li><strong>Highly Qualified Pilots:</strong> Our pilots are rigorously trained and hold the necessary certifications to operate a wide range of aircraft, from small turboprops to large business jets.</li>
                    <li><strong>Flexible Scheduling:</strong> We understand that your flight needs can change at a moment's notice. Our flexible scheduling allows you to book our contract pilots when you need them, ensuring your flights run smoothly.</li>
                    <li><strong>Safety First:</strong> Safety is our top priority. Our pilots adhere to strict safety protocols and are committed to providing a secure and comfortable flight experience for you and your passengers.</li>
                    <li><strong>Experienced Crew:</strong> Our experienced crew members are not just pilots; they are aviation professionals who excel in communication and teamwork, ensuring a seamless flight operation.</li>
                    <li><strong>Global Reach:</strong> Whether you're flying domestically or internationally, our contract pilot services are available worldwide. We handle all necessary planning, permits, and clearances for international flights.</li>
                  </ul>                  
                  </small>
              </h3>
            </div>
            <div className="title">
              <h3>
                How It Works <br />
                <small>
                    <ul>
                        <li><strong>Consultation:</strong> We start by understanding your flight requirements through a consultation.</li>
                        <li><strong>Pilot Selection:</strong> Based on your needs, we select the most suitable pilots from our team.</li>
                        <li><strong>Scheduling:</strong> We work with your schedule to ensure a smooth and timely flight operation.</li>
                        <li><strong>Flight Execution:</strong> Our pilots take care of all aspects of the flight, from pre-flight checks to landing.</li>
                        <li><strong>Feedback and Improvement:</strong> We value your feedback to continually improve our services.</li>
                    </ul>
                </small>
              </h3>
            </div>
            </div>
        </Container>
      </div>
    </>
  );
}

export default ContractPiloting;
