import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";

// styles
import "./assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";

import AboutLandingPage from "pages/AboutLandingPage.js"
import ManagementLandingPage from "pages/ManagementLandingPage.js"
import ConsultingLandingPage from "pages/ConsultingLandingPage.js"
import AcquisitionSalesLandingPage from "pages/AcquisitionSalesLandingPage.js"
import './index.css';
import ContractPilotLandingPage from 'pages/ContractPilotLandingPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<AboutLandingPage />} />
      <Route path="/management" element={<ManagementLandingPage />} />
      <Route path="/acquisition-and-sales" element={<AcquisitionSalesLandingPage />} />
      <Route path="/consulting" element={<ConsultingLandingPage />} />
      <Route path="/contract-pilot" element={<ContractPilotLandingPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </BrowserRouter>
);
