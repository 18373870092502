/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

function AcquisitionSales() {
  return (
    <>
      <div className="section section-buttons">
        <Container>
          <div className="title">
            <h2>Unlock Your Aircraft Dreams
              <br/>
              <small>Are you in the market for a new aircraft or looking to sell your current one? At Ascend, our Aircraft Acquisition and Sales team is dedicated to making your aircraft transactions a breeze. With our expertise and industry connections, we'll guide you through every step of the process.</small>
            </h2>

          </div>
          <div id="buttons">
            <div className="title">
              <h3>
                Why Choose Ascend for Aircraft Acquisition and Sales? <br />
                <small>
                <ul>
                    <li><strong>Market Insight:</strong> We stay up-to-date with the latest trends and market conditions, ensuring you make informed decisions that maximize the value of your aircraft.</li>
                    <li><strong>Vast Network:</strong> With an extensive network of buyers and sellers worldwide, we connect you with the right opportunities, whether you're buying or selling.</li>
                    <li><strong>Negotiation Expertise:</strong> Trust our skilled negotiators to secure the best deal for you, taking into account your budget, preferences, and aircraft specifications.</li>
                    <li><strong>Comprehensive Evaluations:</strong> For sellers, we conduct thorough evaluations of your aircraft to determine its true market value. For buyers, we ensure that the aircraft you choose meets all your requirements.</li>
                  </ul>
                  </small>
              </h3>
            </div>
            <div className="title">
              <h3>
                Our Aircraft Acquisition Services <br />
                <small>
                  <ul>
                    <li><strong>New Aircraft Purchase:</strong> Looking to acquire the latest model or a brand-new aircraft? We'll help you navigate the process, from selection to delivery.</li>
                    <li><strong>Pre-owned Aircraft Purchase:</strong> Whether you seek a pre-owned gem or a cost-effective option, we have access to a wide range of quality pre-owned aircraft.</li>
                  </ul>                  
                  </small>
              </h3>
            </div>
            <div className="title">
              <h3>
                Our Aircraft Sales Services <br />
                <small>
                    <ul>
                        <li><strong>Market Listing:</strong> We create compelling listings for your aircraft, showcasing its features and advantages to potential buyers.</li>
                        <li><strong>Marketing and Promotion:</strong> Utilize our marketing expertise to reach a broader audience of potential buyers, ensuring a faster and more successful sale.</li>
                        <li><strong>Negotiation and Closing:</strong> Leave the negotiation and closing processes to us, so you can focus on your business.</li>
                    </ul>
                </small>
              </h3>
            </div>
            <div className="title">
              <h3>
                Discover Your Aircraft's True Potential <br />
                <small>Whether you're buying your dream aircraft or selling to upgrade, Ascend Aircraft Acquisition and Sales is here to guide you. Our team's dedication to transparency, professionalism, and exceptional customer service sets us apart in the industry.</small>
              </h3>
            </div>
            </div>
        </Container>
      </div>
    </>
  );
}

export default AcquisitionSales;
